<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Modal } from '@/types'
import { formatWalletAddress } from '@injectivelabs/utils'
import { ROUTES } from '@/app/utils/constants'

const route = useRoute()
const router = useRouter()
const modalStore = useModalStore()
const walletStore = useWalletStore()
const ninjaPassStore = useNinjaPassStore()
const confetti = useConfetti()

type Theme = 'light' | 'dark'
const LOCAL_STORAGE_THEME_KEY = 'theme'
const darkMode = useState('theme', () => false)
const setTheme = (newTheme: Theme) => {
  localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme)
  darkMode.value = newTheme === 'dark'
}

const props = defineProps({
  isSidebarOpen: Boolean
})

const sidebarOpenTopbar = ref('sidebar:opened')

const emit = defineEmits<{
  (e: 'sidebar:closed'): void
  (e: 'sidebar:opened'): void
}>()

const isScrolled = ref(false);
const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
}

const marketsDropdown = ref(false)

const isUserConnectedProcessCompleted = ref(false)

const isUserWalletConnected = computed(() => walletStore.isUserWalletConnected)

const hasNinjaPassCodes = computed(() => {
  if (!ninjaPassStore.codes) {
    return false
  }

  return ninjaPassStore.codes.length > 0
})

watch(
  () => isUserWalletConnected,
  (newIsUserWalletConnected) => {
    if (!newIsUserWalletConnected) {
      isUserConnectedProcessCompleted.value = false
    }
  }
)

watch(darkMode, (selected) => {
  setTheme(selected ? 'dark' : 'light')
})

onMounted(() => {
  if (isUserWalletConnected) {
    isUserConnectedProcessCompleted.value = true
  }

  const isDarkModePreferred = window.matchMedia('(prefers-color-scheme: dark)').matches
  const themeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme
  if (themeFromLocalStorage) {
    setTheme(themeFromLocalStorage)
  } else {
    setTheme(isDarkModePreferred ? 'dark' : 'light')
  }

  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

function handleSidebarToggle() {
  const isTopbarDark = document.getElementById('topbar')

  if (props.isSidebarOpen) {
    emit('sidebar:closed')
    if (isTopbarDark) {
      isTopbarDark.style.backgroundColor = '';
    }
    return
  }

  if (isTopbarDark) {
    isTopbarDark.style.backgroundColor = 'black';
  }

  emit('sidebar:opened')
}


function handleShowNinjaPassModal() {
  modalStore.openModal({ type: Modal.NinjaPassWinner })

  confetti.showConfetti()
}

const formattedInjectiveAddress = computed(() =>
  formatWalletAddress(walletStore.injectiveAddress)
)
function handleDisconnect() {
  walletStore.logout()

  if (ROUTES.walletConnectedRequiredRouteNames.includes(route.name as string)) {
    router.push({ name: 'index' })
  }
}

function openMarketsDropdown() {
  darkMode.value = !darkMode.value
}

const excludedRoutes = ['/spot', '/futures', '/convert']

const scrollRef = ref(null)
const wrapperClasses = computed(() => {
  const isExcludedRoute = excludedRoutes.includes(route.path);
  const bgClasses = isScrolled.value && !isExcludedRoute ? 'bg-gray-100 dark:bg-gray-1000 boxshadow-b' : '';
  const positionClasses = route.path.indexOf('/spot') === 0 || route.path.indexOf('/futures') === 0 || route.path.indexOf('/convert') === 0 ? 'relative' : 'sticky top-0';
  return [bgClasses, positionClasses];
})

</script>

<template>
  <header
    id="topbar"
    class="w-full topbar-z-index flex h-18 items-center justify-between"
    :class="wrapperClasses"
  >
    <div class="flex px-2 lg:px-6 items-center justify-between gap-3">
      <button
        class="px-4 border-r border-gray-600 textmode-neutral-500 lg:hidden col-span-2 lg:col-span-0"
        @click.stop="handleSidebarToggle"
      >
        <BaseIcon v-if="isSidebarOpen" name="close" class="w-6 h-6" />
        <BaseIcon v-else name="menu" class="w-6 h-6" />
      </button>
      <div class="hidden lg:flex min-w-14">
        <div
          class="cursor-pointer mx-2 items-start"
          @click="$router.push({ name: 'index' })"
        >
          <img src="/logo-v3.svg" class="w-auto h-8" alt="Wavely" />
        </div>
      </div>
      <LayoutNav class="hidden lg:flex ml-4" />
    </div>

    <PartialsCommonSearchbarMarketsDropdown class="flex w-full justify-center items-start ml-1 mr-2 xs:mr-6" />
    
    <div class="flex items-center justify-end mr-2 sm:mr-8">
        <LayoutNavItemDummy
          v-if="isUserWalletConnected && hasNinjaPassCodes"
          class="flex px-0 w-10 items-center justify-center"
          @click="handleShowNinjaPassModal"
        >
          <BaseIcon name="gift" class=" w-4 h-4" />
        </LayoutNavItemDummy>

        <div id="wallet" class="flex bg-primaryLight-500 dark:bg-primaryDark-500 px-2 py-1 rounded-3xl">
          <LayoutWallet />
          <LayoutNavWalletHoverMenu parentId="wallet"
          >
            <template #default>
              <div class="flex justify-center items-center p-2 border-l border-primaryLight-800 dark:border-primaryDark-800">
                <BaseIcon
                  name="caret-down"
                  class="text-primaryLight-800 group-hover:text-primaryLight-500 dark:text-primaryDark-800 dark:group-hover:text-primaryDark-500 w-6 h-6"
                />
                </div>
            </template>

            <template #content>
              <div
              id="wallet-popper"
              class="flex flex-col justify-center align-middle p-3 gap-4"
              :class="[{ 'bg-primaryDark-500': darkMode }, { 'bg-primaryLight-500': !darkMode }]"
              >
                <div
                v-if="walletStore.isUserWalletConnected"
                class="flex flex-col gap-4 rounded-3xl p-4"
                :class="[{ 'bg-primaryDark-600': darkMode }, { 'bg-primaryLight-400': !darkMode }]"
                >
                  <div>
                    <div class="flex flex-col py-3 px-4">
                      <div class="flex justify-between pb-2">
                        <span
                        class="text-sm font-semibold"
                        :class="[{ 'text-white': darkMode }, { 'text-gray-700': !darkMode }]"
                        >
                          {{ $t('navigation.myAccount') }}
                        </span>
                        <span
                          class="hover:text-opacity-80 cursor-pointer text-xs font-medium rounded-lg px-2 py-1"
                          :class="[{ 'text-white bg-primaryDark-700': darkMode }, { 'text-gray-700 bg-primaryLight-200': !darkMode }]"
                          @click="handleDisconnect"
                        >
                          {{ $t('navigation.disconnect') }}
                        </span>
                      </div>
                      <LayoutWalletDetailsConnectedWallet
                        :wallet="walletStore.wallet"
                      />
                    </div>
                  </div>
                </div>
                <LayoutWallet
                  v-else
                />
                <LayoutNavItem
                  v-if="isUserWalletConnected"
                  class="flex rounded-xl py-6"
                  data-cy="header-activity-link"
                  :to="{ name: 'activity' }"
                  :class="[{ 'hover:bg-primaryDark-600 text-gray-100': darkMode }, { 'hover:bg-primaryLight-400 text-gray-800': !darkMode }]"
                >
                  {{ $t('navigation.activity') }}
                </LayoutNavItem>

                <LayoutNavItem
                  v-if="isUserWalletConnected"
                  class="flex rounded-xl py-6"
                  data-cy="header-account-link"
                  :to="{ name: 'account' }"
                  :class="[{ 'hover:bg-primaryDark-600 text-gray-100': darkMode }, { 'hover:bg-primaryLight-400 text-gray-800': !darkMode }]"
                >
                  {{ $t('navigation.account') }}
                </LayoutNavItem>

                <div
                class="py-3 flex items-center justify-center cursor-pointer rounded-2xl h-12 w-full hover:bg-[rgba(0,0,0,0.2)] dark:hover:bg-[rgba(0,0,0,0.4)]"
                @click.stop="$emit('toggle')"
                >
                  <div v-if="darkMode" class="flex gap-3 text-gray-100">
                    <img src="/icons/moon.svg"
                    class="w-5 h-5 fade"
                    >
                    {{ 'Dark Mode' }}
                  </div>
                  <div v-if="!darkMode" class="flex gap-3 text-gray-700">
                    <img src="/icons/sun.svg"
                    class="w-5 h-5 fade text-white"
                    >
                    {{ 'Light Mode' }}
                  </div>
                </div>
              </div>
            </template>
          </LayoutNavWalletHoverMenu>
        </div>
      </div>
  </header>
</template>

<style>
.topbar-z-index {
  z-index: 1600;
}
</style>